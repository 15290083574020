const state = {};

const getters = {};

const mutations = {};

const actions = {
  async submit_notification(_, payload) {
    return window.axios.post("/notifications", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
