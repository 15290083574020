"use strict";

import Vue from "vue";
import axios from "axios";
import store from "../store";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_LIVE_API
    : process.env.VUE_APP_STAGING_API;

let config = {
  baseURL: baseUrl || process.env.apiUrl || "",
  timeout: 30 * 1000, // Timeout
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = store.state?.auth?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    response = response.data;
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      store.dispatch("auth/logout_user");
      window.location.href = `/sign_in?redirect=${Vue.$router.history.current.fullPath}`;
    }
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    _axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
