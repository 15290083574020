import users from "./users";
import dashboard from "./dashboard";
import transactions from "./transactions";
import logs from "./logs";
import suppliers from "./suppliers";
import notifications from "./notiifications";

const protectedRoutes = [
  ...dashboard,
  ...users,
  ...transactions,
  ...logs,
  ...suppliers,
  ...notifications,
];

export default protectedRoutes;
