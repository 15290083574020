const state = {};

const getters = {};

const mutations = {};

const actions = {
  async get_categories(_, supplierId) {
    return window.axios.get(`/suppliers/${supplierId}/categories`);
  },
  async enable_disable_category(_, payload) {
    return window.axios.post("/update-category", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
