import auth from "../middleware/auth";

const routes = [
  {
    path: "/suppliers",
    name: "Suppliers",
    component: () => import("../../views/protected/suppliers/index.vue"),
    meta: {
      layout: () => import("../../layouts/authenticated"),
      middleware: [auth],
    },
  },
  {
    path: "/suppliers/:id/categories",
    name: "Categories",
    component: () => import("../../views/protected/suppliers/categories.vue"),
    meta: {
      layout: () => import("../../layouts/authenticated"),
      middleware: [auth],
    },
  },
];

export default routes;
